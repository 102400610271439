// Entry point for the build script in your package.json

// Rails
import Rails from "@rails/ujs";
Rails.start();

// Nav toggle
import navToggle from "./lib/navToggle";
navToggle();

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();
